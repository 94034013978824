import React from "react";

import logo from "../imgs/icon.png"
import '../styles/SideNav.css';

function SideNav({tab, onClickFunction}){
    return(
        <div className="sidenav">
            <div className="side-nav-header-div">
                <div className="side-nav-header-text">
                    Allocate
                </div>
                <img className="side-nav-header-img" src={logo}/>
            </div>
            <div className="side-nav-menu-header-div">Menu</div>
            <div className="side-nav-a-div">
                <a className={tab == 0? "tabOn":"tab"} href="#" onClick={() => onClickFunction(0)}>Add Patient</a>
                <a className={tab == 1? "tabOn":"tab"} href="#" onClick={() => onClickFunction(1)}>My Trials</a>
                <a className={tab == 2? "tabOn":"tab"} href="#" onClick={() => onClickFunction(2)}>Patient Log</a>
                <a className={tab == 3? "tabOn":"tab"} href="#" onClick={() => onClickFunction(3)}>Recruitment Summary</a>
                <a className={tab == 4? "tabOn":"tab"} href="#" onClick={() => onClickFunction(4)}>Manage Trials</a>
            </div>
            
        </div>
    )
}

export default SideNav;