import {runTransaction, doc, getDoc, setDoc, getFirestore,  collection, getDocs } from "firebase/firestore";
import React from "react";
import Firebase from "./Firebase";
import Account from "./Account";
import { serverTimestamp } from "firebase/firestore";
import { get, set } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import firebase from "firebase/compat/app";


class Trial {

    id;
    
    constructor (docSnap) {
        this.name = docSnap.get("name");
        this.nameShorthand = docSnap.get("name shorthand");
        this.patientsPerSite = docSnap.get("patients per site");
        this.totalPatients = docSnap.get("total patients");
        this.researchCoordinators = docSnap.get("research coordinators");
        this.sitesFull = docSnap.get("sites");
        this.sitesShorthandFull = docSnap.get("sites shorthand");
        this.description = docSnap.get("description");
        const logoName = docSnap.get("logo");
        if (logoName !== undefined){
          const storage = getStorage();
          getDownloadURL(ref(storage, logoName))
          .then((url) => {
            this.logoURL = url;
          })
          .catch((error) => {
            console.log(error);
          });
        }
        this.sites = this.sitesFull;
        this.sitesShorthand = this.sitesShorthandFull;
        this.sites = this.sitesFull.filter(n => n != null);
        this.sitesShorthand = this.sitesShorthandFull.filter(n => n != null);
        this.trialHeadEmail = docSnap.get("trial head email");
        this.variable1 = docSnap.get("variable 1");
        this.variable1Array = docSnap.get("variable 1 array");
        this.variable2 = docSnap.get("variable 2");
        this.variable2Array = docSnap.get("variable 2 array");
        this.variable3 = docSnap.get("variable 3");
        this.variable3Array = docSnap.get("variable 3 array");
        this.variable4 = docSnap.get("variable 4");
        this.variable4Array = docSnap.get("variable 4 array");
    };

    async addPatient(variables, email){
        const trialRef = doc(Firebase.db, "trials", this.id);
        try {
            return await runTransaction(Firebase.db, async (transaction) => {
              const trialSnapshot = await transaction.get(trialRef);
              if (!trialSnapshot.exists()) {
                throw "Document does not exist!";
              }
              var indexName = "index";
              var tableName = "table";
              for (var k = 1; k < variables.length; k++){
                if (variables[k] === "default"){
                  break;
                }
                indexName = indexName + " " + variables[k];
                tableName = tableName + " " + variables[k];
              }
              console.log(indexName);
              console.log(tableName);
              var nextIndex = trialSnapshot.get(indexName);
              var tableDoc = doc(Firebase.db, "trials/" + this.id + "/" + tableName, "value " + nextIndex);
              var tableSnapshot = await transaction.get(tableDoc);
              var allocated = tableSnapshot.get("allocated");
              while (allocated){
                nextIndex = nextIndex + 1;
                tableDoc = doc(Firebase.db, "trials/" + this.id + "/" + tableName, "value " + nextIndex);
                tableSnapshot = await transaction.get(tableDoc);
                allocated = tableSnapshot.get("allocated");
              }
              var newPatientsPerSite = trialSnapshot.get("patients per site");
              var newTotalPatients = trialSnapshot.get("total patients");
              newPatientsPerSite[variables[1]] = newPatientsPerSite[variables[1]] + 1;
              newTotalPatients = newTotalPatients + 1;
              this.patientsPerSite = newPatientsPerSite;
              this.totalPatients = newTotalPatients;
              const patientId = this.nameShorthand.replaceAll(" ", "-") + "-" + this.totalPatients;
              transaction.update(trialRef, {
                [indexName]: (nextIndex + 1),
                "patients per site": this.patientsPerSite,
                "total patients": this.totalPatients
              });
              
              transaction.update(tableDoc, {
                "allocated": true,
                "randomized by": Account.email,
                "date": serverTimestamp(),
                "patient id": patientId
              })
              var value = tableSnapshot.get("arm");
              console.log("the arm is "  + value);
              var returnedMap = {
                "arm": value,
                "patient id": patientId
              }
              return returnedMap
            }).then(async (map) =>{
              if (map == undefined){
                return "failed";  
              }
              var patientId = map["patient id"];
              console.log(patientId);
              var arm = map["arm"];
              await Account.updatePatientsRecruited(this.id);
              await Account.updateLog(this.name, this.id, patientId, this.sites[variables[1]], new Date(), 
                this.variable1!=undefined?this.variable1Array[variables[2]]:undefined,
                this.variable1,
                this.variable2!=undefined?this.variable2Array[variables[3]]:undefined,
                this.variable2,
                this.variable3!=undefined?this.variable3Array[variables[4]]:undefined,
                this.variable3,
                this.variable4!=undefined?this.variable4Array[variables[5]]:undefined,
                this.variable4);
              console.log("Transaction successfully committed! " + patientId);
              Account.sendEmail("Patient Randomized", "Patient: " + patientId + " randomized into " + this.name + " by " + Account.username + " (" + Account.email + ")", this.trialHeadEmail);
              return patientId;
            });
        } catch (e) {
          console.log("Transaction failed: ", e);
          return "failed";
        }
    }

    async refreshPatientValues(){
      const trialRef = doc(Firebase.db, "trials", this.id);
      const trialDoc = await getDoc(trialRef)
      this.patientsPerSite = trialDoc.get("patients per site");
      this.totalPatients = trialDoc.get("total patients");
      return true
    }

    static async removeCoordinator(trialId, email){
      const trialRef = doc(Firebase.db, "trials", trialId);
      const userRef = doc(Firebase.db, "users", email);
      try {
        await runTransaction(Firebase.db, async (transaction) => {
              const trialSnapshot = await transaction.get(trialRef);
              const userSnapshot = await transaction.get(userRef);
              if (!trialSnapshot.exists()) {
                throw "Trial does not exist!";
              }
              var coords = trialSnapshot.get("research coordinators");
              const index = coords.indexOf(email);
              if (index != -1){
                coords.splice(index, 1);
              }
              transaction.update(trialRef, {"research coordinators": coords});
              if (!userSnapshot.exists()){
                return;
              }
              var sites = userSnapshot.get("sites");
              var admin = userSnapshot.get("admin");
              var trials = userSnapshot.get("trials");
              var newAdmin = {};
              var newSites = {};
              for (var key in admin){
                if (key != trialId){
                  newAdmin[key] = admin[key];
                }
              }
              for (var key in sites){
                if (key != trialId){
                  newSites[key] = sites[key];
                }
              }
              const ind = trials.indexOf(trialId);
              if (ind != -1){
                trials.splice(ind, 1);
              }
              transaction.update(userRef, {
                "sites": newSites,
                "admin": newAdmin,
                "trials": trials
              });
        });
        console.log("Transaction successfully committed!");
      } 
      catch (e) {
        console.log("Transaction failed: ", e);
      }
      
    }

    static async removeSite(trialId, site){
      
    }

    static async addCoordinator(trialId, email, sites){
      // create reference for trial and user
      const trialRef = doc(Firebase.db, "trials", trialId);
      const userRef = doc(Firebase.db, "users", email);
      // get doc for user
      return getDoc(userRef).then(async (userDoc) => {
        var newEmail = userDoc.get("email");
        // check if user already exists
        if (newEmail != undefined){
          // user exists
          try{
            return await runTransaction(Firebase.db, async (transaction) => {

              // add coordinator to trial
              // add trial to coordinator
              const trialSnapshot = await transaction.get(trialRef);
              const userSnapshot = await transaction.get(userRef);
              var researchCoordinators = trialSnapshot.get("research coordinators");
              researchCoordinators.push(email);
              transaction.update(trialRef, {"research coordinators": researchCoordinators});
              var adminMap = userSnapshot.get("admin");
              adminMap.set(trialId, false);
              transaction.update(userRef, {"admin": adminMap});
              var sitesMap = userSnapshot.get("sites");
              sitesMap.set(trialId, sites);
              transaction.update(userRef, {"sites": sitesMap});

              var patientsRecruited = userSnapshot.get("patients recruited");
              patientsRecruited.set(trialId, 0);
              transaction.update(userRef, {"patients recruited": patientsRecruited});
      
              var trials = userSnapshot.get("trials");
              trials.push(trialId);
              transaction.update(userRef, {"trials": trials});

              Account.sendEmail("You Have been Added to a Trial", "You have been added to the trial " + this.name + " as a research coordinator", email);
              
              return true;
            })
          }
          catch (error){
           toast("There was a problem adding this coordinator");
           return false;
          }
        }
        else{
          // user doesnt exist
          try{
            // create new user
            const password = Account.randomString();
            const auth = getAuth();
            return await createUserWithEmailAndPassword(auth, email, password).then(async (result) => {
              if (result.user != undefined){
                const adminMap = {
                  [trialId]: "false"
                };
                const siteMap = {
                  [trialId]: sites
                };
                const patientsRecruited = {
                  [trialId]: 0
                };
                const trials = [];
                trials.push(trialId);
                const userMap = {
                  "user name": "User Name",
                  "email": email,
                  "security question": 0,
                  "security answer": "",
                  "log": [],
                  "admin": adminMap,
                  "trials": trials,
                  "sites": siteMap,
                  "patients recruited": patientsRecruited,
                };
                return await runTransaction(Firebase.db, async (transaction) => {
                  // add coordinator to trial
                  // add trial to coordinator
                  const trialSnapshot = await transaction.get(trialRef);
                  transaction.set(userRef, userMap);
                  var researchCoordinators = trialSnapshot.get("research coordinators");
                  researchCoordinators.push(email);
                  transaction.update(trialRef, {"research coordinators": researchCoordinators});
                  Account.sendEmail("Welcome To Allocate", "You have been added to the trial " + this.name + " as a research coordinator. And here ill add stuff about how to get the app, web app and instructions on logging in." + "\nYour password is : " + password, email);
                  // send email here potentially
                  return true;
                })
              }
              else{
                toast("There was a problem adding this coordinator");
                return false;
              }
            })
          }
          catch(error){
            toast("There was a problem adding this coordinator");
            console.log("error " + error);
            return false;
          }
          return true;
        }
      });
    }
    
    toString() {
        return this.name + "(" + this.id + ")";
    }
}


export default Trial;