import '../App.css';
import Account from '../Account';
import Plot from 'react-plotly.js';

import React, { useState } from "react";

function RecruitmentSummaryComponent({setHeader}) {
  
  const [trialOn, setTrialOn] = useState(0);
  const [started, setStarted] = useState(false);
  
  function trialOptions(){
    var optionArray = [];
    optionArray.push(<option hidden disabled value selected>Select a Trial</option>)
    for (var i = 0 ; i < Account.trials.length; i ++){
      optionArray.push(<option value={i}> {Account.trials[i].name} </option>)
    }
    return optionArray;
  }

  function setTrial(val){
    setHeader(Account.trials[val].nameShorthand);
    setStarted(true);
    setTrialOn(val);
  }

  var colours = [
    'rgba(236, 239, 0, 1)',
    'rgba(0, 156, 237, 1)', 
    'rgba(237, 0, 0, 1)',
    'rgba(2, 204, 59, 1)',
    'rgba(190, 2, 204, 1)',
    'rgba(0, 239, 167, 1)',
    ]
  

  return (
      <div className='recruitment-summary-div' id='recruitment-summary-div'>
        <div className='description-div'>
          <div className='description-div-text1'>
            Data Relating to Trial Recruitment
          </div>
          <div className='description-div-text2'>
          Select a trial to see available information about patient recruitment rate, and overall values.
          </div>
        </div>
        <div className='trial-label-div'>Trial</div>
        <select style={{color: started?'white':'#ffffffaf'}} className="classic" id='trial-select' onChange={event => {setTrial(event.target.value)}}>
            {trialOptions()}
      </select>
      <Plot className="plotly-graph" data={[
          {
            x: Account.trials[trialOn].sitesShorthand, y: Account.trials[trialOn].patientsRecruited, type: 'bar', 
            marker:{ color: colours[1] }, orientation: 'v', 
            hovertemplate: '<b>%{text}</b><extra></extra>',
            text: Account.trials[trialOn].sites,
            textposition:"none"
        },
        ]}
        layout={{
          autosize:false,
            font: {
                family:"Sen-Regular"
            },
            yaxis: {
                tickmode: "linear",
                tick0: 0,
                dtick: 1,
              },
              height:500,
              width:500,
              title: 'Recruitment Rate by Site'}}
             config={{displaylogo: false, responsive: true}}

      />
      <Plot className="plotly-graph" data={[
          {
            x: Account.trials[trialOn].sitesShorthand, y: Account.trials[trialOn].patientsRecruited, type: 'bar', 
            marker:{ color: colours[1] }, orientation: 'v', 
            hovertemplate: '<b>%{text}</b><extra></extra>',
            text: Account.trials[trialOn].sites,
            textposition:"none"
        },
        ]}
        layout={{
          autosize:false,
            font: {
                family:"Sen-Regular"
            },
            yaxis: {
                tickmode: "linear",
                tick0: 0,
                dtick: 1,
              },
              height:500,
              width:500,
              title: 'Number Of Patients Recruited By Site'}}
             config={{displaylogo: false, responsive: true}}

      />
      </div>
    )
}

export default RecruitmentSummaryComponent;