import './App.css';
import Header from './Components/Header';
import SideNav from './Components/SideNav';
import CompactSideNav from './Components/CompactSideNav';
import Account from './Account';
import Firebase from './Firebase';
import logo from "./imgs/dice.svg";
import './styles/LoginScreen.css';
import LoadingComponent from './Components/LoadingComponent';


import { doc, onSnapshot, getDoc, setDoc, getFirestore,  collection } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import React, { useState, useEffect } from "react";
import AddPatientComponent from './Components/AddPatientComponent';
import ManageTrialComponent from './Components/ManageTrialComponent';
import MyAccountDropdownComponent from './Components/MyAccountDropdownComponent';
import RecruitmentSummaryComponent from './Components/RecruitmentSummaryComponent';
import MyTrialsComponent from './Components/MyTrialsComponent';
import ChangePasswordComponent from './Components/ChangePasswordComponent';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatientLogComponent from './Components/PatientLogComponent';


function App() {
  const [infoSet, setInfoSet] = useState(false);
  const [trialOnInitial, settrialOnInitial] = useState(-1);
  const [tabIndex, setTabIndex] = useState(1);
  const [trialName, setTrialName] = useState("");
  const headerTexts = ["Add Patient", "My Trials", "Patient Log", "Recruitment Summary", "Manage Trials", "Change Password", "Reset Password"];
  const [loggedIn, setLoggedIn]  = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);


  const useDocumentTitle = () => {
    useEffect(() => {
      document.title = "Allocate"
    }, []);
  };

  useDocumentTitle();
  
  function wrongPassword(){
    toast("Wrong email/password combination");
    setLoggingIn(false);
  }
  
  async function setData(email){
    console.log("setting data");
    const docRef = doc(Firebase.db, "users", email);
    const docSnap = await getDoc(docRef);
    Account.setValues(docSnap).then(() => {
      setInfoSet(true);
      setLoggedIn(true);
      setLoggingIn(false);
    });
  }
  
  function setTab(value){  
    if (tabIndex == value){
      return;
    }
    settrialOnInitial(-1); 
    setTrialName("");
    setTabIndex(value);
  }

  function setTrialHeader(val){
    setTrialName(val);
  }

  function logIn(e){
    Firebase.initialize();
    e.preventDefault();
    console.log("submitting");
    const auth = getAuth();
    setLoggingIn(true);
    signInWithEmailAndPassword(auth, e.target.email.value, e.target.password.value)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        setData(user.email);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      wrongPassword();
  });
  }

  return (
    <div>    
      <meta name="viewport" content="width=device-width,initial-scale=1.0"/>
      <div className='main-div' id='main'>
        {loggedIn?
        <div className='web-app-div'>
          <div className='mobile-div'>
            <CompactSideNav tab = {tabIndex} onClickFunction={(val) => setTab(val)}/>
            <div className='top-div-mobile'>
              <Header text1 = {headerTexts[tabIndex]} text2 = {trialName}/>
            </div>
          </div>
          <div className='desktop-div1'>
            <SideNav tab = {tabIndex} onClickFunction={(val) => setTab(val)}/>
            <div className='top-div-mobile'>
              <Header text1 = {headerTexts[tabIndex]} text2 = {trialName}/>
            </div>
          </div>
          <div className='desktop-div2'>
            <SideNav tab = {tabIndex} onClickFunction={(val) => setTab(val)}/>
            <div className='top-div'>
              <Header text1 = {headerTexts[tabIndex]} text2 = {trialName}/>
            </div>
          </div>
          <MyAccountDropdownComponent changePasswordFunction = {() => setTab(5)} logOutFunction={() => {
              const auth = getAuth();
              signOut(auth).then(() => {
                setTab(1);
                setLoggedIn(false);
              }).catch((error) => {
              });
              }}
              open = {false}/>
        {infoSet &&
          <div className='full-div'>
            {tabIndex === 0? <AddPatientComponent id="addPatientComponent" initialTrialOn={trialOnInitial} setHeader = {(val) => setTrialHeader(val)}/>: <div/>}
            {tabIndex === 1? <MyTrialsComponent addPatientFunction = {(index) => {
              settrialOnInitial(index);
              setTrialName(Account.trials[index].nameShorthand);
              setTabIndex(0);
            }} setHeader = {(val) => setTrialHeader(val)}/>: <div/>}
            {tabIndex === 2? <PatientLogComponent/>: <div/>}
            {tabIndex === 3? <RecruitmentSummaryComponent setHeader = {(val) => setTrialHeader(val)}/>: <div/>}
            {tabIndex === 4? <ManageTrialComponent setHeader = {(val) => setTrialHeader(val)}/>: <div/>}
            {tabIndex === 5? <ChangePasswordComponent changeFunction = {() => {
              setTab(0)
              }}
              resetFunction = {()=> {}}
              setHeader = {()=> {setTrialHeader("Reset Password")}}
              />: <div/>}
          </div>
        }
      </div>:
        <div className='log-in-div'>
          <div className='log-in-left-div'>
            <div className="log-in-header-div">
              <div className="log-in-header-text">
                Allocate
              </div>
              <img className="log-in-header-img" src={logo}/>
            </div>
            <div className='log-in-title'>
              Welcome Back to <font color="#057DB8">Allocate</font>
            </div>
            <div className='log-in-flavour'> Log into your account
            </div>
            {loggingIn?
              <div className='loading-log-in-div'>
                <LoadingComponent text={"Logging In"}/>
              </div>:
              <form id="log-in-form" onSubmit={(data) => logIn(data)}>
                <input name="email" type="text" className='email-input' placeholder="Email"></input>
                <input name="password" type="password" className='password-input' placeholder="Password"></input>
                <a className='forgot-password-a' href="#">
                  Forgot Password?
                </a>
                <button type="submit" className='log-in-button' href="#" >
                  Log In
                </button>
              </form>}
            
          </div>  
        </div>
      }
      <ToastContainer position="bottom-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                theme="light"/>
      </div>
    </div>
    
  )
}

export default App; 