import React, { useState } from "react";

import logo from "../imgs/icon.png"
import hamburger from "../imgs/hamburger.svg"
import blueHamburger from "../imgs/hamburger_blue.svg"


function CompactSideNav({tab, onClickFunction}){

    const [open, setIsOpen] = useState(false);

    function closeAndOpen(){
        if (open){
            document.getElementById("sidenav").style.left = "-200px";
            document.getElementById("sidenav-full").style.width = "0%";
            setIsOpen(false);
        }
        else{
            document.getElementById("sidenav").style.left = "0px";
            document.getElementById("sidenav-full").style.width = "100%";
            setIsOpen(true);
        }
    }

    return(  
        <div>
            <a className="sidenav-a" href="#" onClick={() => closeAndOpen()}>
                <img className="side-nav-header-img3" src={blueHamburger}/>
            </a>
            <div className="sidenav-full" id="sidenav-full">
                <div className="sidenav-small" id="sidenav">
                    <a className="side-nav-header-a" href="#" onClick={() => closeAndOpen()}>
                        <img className="side-nav-header-img2" src={hamburger}/>
                        <div className="side-nav-header-text">
                            Allocate
                        </div>
                    </a>
                    <div className="side-nav-menu-header-div">Menu</div>
                    <div className="side-nav-a-div">
                        <a className={tab == 0? "tabOn":"tab"} href="#" onClick={() => {
                            onClickFunction(0)
                            closeAndOpen()
                            }}>Add Patient</a>
                        <a className={tab == 1? "tabOn":"tab"} href="#" onClick={() => {
                            onClickFunction(1)
                            closeAndOpen()
                            }}>My Trials</a>
                        <a className={tab == 2? "tabOn":"tab"} href="#" onClick={() => {
                            onClickFunction(2)
                            closeAndOpen()
                            }}>Patient Log</a>
                        <a className={tab == 3? "tabOn":"tab"} href="#" onClick={() => {
                            onClickFunction(3)
                            closeAndOpen()
                            }}>Recruitment Summary</a>
                        <a className={tab == 4? "tabOn":"tab"} href="#" onClick={() => {
                            onClickFunction(4)
                            closeAndOpen()
                            }}>Manage Trials</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompactSideNav;